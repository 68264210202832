import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalAppStyle = createGlobalStyle`
    html body {
        font-family: 'Source Sans Pro', sans-serif;
        margin: 0;
        padding: 0;
    }
`;
export const BaseLayout = ({ children }) => (
    <>
        <GlobalAppStyle />
        {children}
    </>
)